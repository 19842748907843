<template>
  <v-dialog
    v-model="show"
    max-width="500px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        dark
        color="error"
      >
        <v-toolbar-title>
          Confirme!
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <p class="ma-0 black--text">
          Tem certeza que deseja deletar este item?
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          @click="show = false"
        >
          <v-icon
            left
            v-text="'mdi-cancel'"
          />
          Cancelar
        </v-btn>
        <v-btn
          color="error"
          :loading="loading"
          outlined
          @click="$emit('confirm')"
        >
          <v-icon class="mr-2">
            mdi-delete
          </v-icon>
          Deletar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

  }
</script>
